export default {
    Dashboards: 'Панель',
    CRM: 'CRM',
    Analytics: 'Аналитика',
    eCommerce: 'Электронная коммерция',
    Calendar: 'Календарь',
    Chat: 'Чат',
    Email: 'Почта',
    Invoice: 'Счет',
    List: 'Лист',
    Preview: 'Aperçu',
    Edit: 'Редактировать',
    Add: 'Добавить',
    User: 'Пользователь',
    'User List': "Список пользователей",
    'User View': 'Просмотр пользователя',
    'APPS AND PAGES': 'APPLICATIONS ET PAGES',
    Pages: 'Страницы',
    Authentication: 'Идентификация',
    'Login V1': 'Connexion V1',
    'Login V2': 'Connexion V2',
    'Register V1': "S'inscrire V1",
    'Register V2': "S'inscrire V2",
    'Forgot Password V1': 'Mot de passe oublié V1',
    'Forgot Password V2': 'Mot de passe oublié V2',
    'Reset Password V1': 'Réinitialiser le mot de passe V1',
    'Reset Password V2': 'Réinitialiser le mot de passe V2',
    Miscellaneous: 'Divers',
    'Under Maintenance': 'En maintenance',
    Error: 'Ошибка',
    'Coming Soon': 'Bientôt disponible',
    'Not Authorized': 'Не авторизован',
    'Knowledge Base': 'Base de connaissances',
    'Account Settings': 'Настройки аккаунта',
    Pricing: 'Цены',
    FAQ: 'FAQ',
    'USER INTERFACE': 'INTERFACE UTILISATEUR',
    Typography: 'Typographie',
    Icons: 'Иконки',
    Gamification: 'Gamification',
    Cards: 'Карты',
    Basic: 'De base',
    Statistics: 'Statistiques',
    Advance: 'Avance',
    Actions: 'Actions',
    Chart: 'Graphique',
    Components: 'Composants',
    Alert: 'Alerte',
    Avatar: 'Avatar',
    Badge: 'Badge',
    Button: 'Кнопка',
    Menu: 'Меню',
    'Expansion Panel': "Panneau d'extension",
    Dialog: 'Dialogue',
    Pagination: 'Pagination',
    Tabs: 'Onglets',
    Tooltip: 'Info-bulle',
    Chip: 'Ébrécher',
    Snackbar: 'Snack-bar',
    Stepper: 'Pas à pas',
    Timeline: 'Chronologie',
    Treeview: 'Arborescence',
    'FORMS & TABLES': 'FORMULAIRES & TABLEAUX',
    'Forms Elements': 'Éléments de formulaires',
    Autocomplete: 'Saisie automatique',
    Checkbox: 'Case à cocher',
    Combobox: 'Boîte combo',
    'File Input': 'Entrée de fichier',
    Radio: 'Radio',
    'Range Slider': 'Curseur de plage',
    Select: 'Sélectionner',
    Slider: 'Glissière',
    Switch: 'Переключатель',
    Textarea: 'Zone de texte',
    Textfield: 'Champ de texte',
    'Date Picker': 'Sélecteur de date',
    'Month Picker': 'Sélecteur de mois',
    'Time Picker': 'Sélecteur de temps',
    Rating: 'Notation',
    'Form Layout': 'Disposition du formulaire',
    'Form validation': 'Validation du formulaire',
    Tables: 'les tables',
    'Simple Table': 'Tableau simple',
    Datatable: 'Table de données',
    'CHARTS AND MAPS': 'CARTES ET CARTES',
    Charts: 'Graphiques',
    'Apex Chart': 'Graphique Apex',
    Chartjs: 'Chartjs',
    'Leaflet Maps': 'Dépliant Cartes',
    Others: 'Autres',
    'Menu Levels': 'Niveaux de menu',
    'Menu Level 2.1': 'Niveau de menu 2.1',
    'Menu Level 2.2': 'Niveau de menu 2.2',
    'Menu Level 3.1': 'Menu Niveau 3.1',
    'Menu Level 3.2': 'Niveau de menu 3.2',
    'Disabled Menu': 'Menu désactivé',
    'Raise Support': 'Augmenter le soutien',
    Documentation: 'Документация',
    'Charts & Maps': 'Graphiques et cartes',
    Apps: 'приложения',
    'User Interface': 'Пользовательский интерфейс',
    'Forms & Tables': 'Formulaires et tableaux',
    Inventory: "Инвентарь",
    All: "Все",
    Published: "Опубликовано",
    Unpublished: "Неопубликованные",
    Uploaded: "Загруженные",
    Create: "Создать",
    Upload: "Загрузить",
    'Upload File': "Загрузить файл",
    'Download the document for fast loading of goods.': "Выгрузить документ для быстрой загрузки товаров.",
    'document template.': "образец документа.",
    'Download': "Скачать",
    'Supported file formats: CSV, XLS, XLSX.': "Поддерживаемые форматы файлов: CSV, XLS, XLSX.",
    'File to upload': "Файл для загрузки",
    Status: "Статус",
    'No file chosen': "Файл не выбран",
    'Total Products': "Количество товара",
    Verified: "Проверенно",
    Unverified: "Непроверенно",
    'Rejected / Banned': "Отклоненный / Бан",
    Draft: 'Черновик',
    Delete: "Удалить",
    Details: "Детали",
    Publish: "Опубликовать",
    'All Products': "Все товары",
    Orders: "Заказы",
    'All Orders': "Все заказы",
    Refunds: "Возвраты",
    Account: "Аккаунт",
    Profile: "Профиль",
    Security: "Безопасность",
    Users: "Пользователи",
    'All users': "Все пользователи",
    Buyers: "Покупатели",
    Sellers: "Продавцы",
    Moderators: "Модераторы",
    Logout: "Выйти",
    'buy now': "Купить",
    'add to card': "Добавить в корзину",
    Date: 'Дата',
    'Product details': "Информация о продукте",
    Reject: 'Отклонить',
    Verify: 'Подтвердить',
    Ban: 'Бан',
    'Description:': 'Описание:',
    'Date:': 'Дата:',
    'Sold Times:': 'Продано раз:',
    'Status:': 'Статус:',
    Payouts: 'Выплаты',
    Paid: 'Оплачены',
    Pending: 'Ожидаются',
    Transactions: "Транзакции",
    Balance: "Баланс",
    'go to storefront': 'перейти к витрине',
    'Update existing products': "Обновить существующие продукты",
    'Choose file': 'Выбрать файл',
    'Purchases can only be refunded for Deal Credits that are directly transferred to your Deal Wallet. Cash refund not supported.': 'Покупки могут быть возмещены только за Кредиты сделки, которые напрямую переведены в ваш Кошелек сделки. Возврат наличными не поддерживается.',
    Unpublish: 'Не публиковать',
    'Total Users': 'Всего пользователей',
    Billing: "Оплаты",
    'Search ...': 'Поиск ...',
    Role: 'Роль',
    'Date of registration': 'Дата регистрации',
    'Buyer\’s balance': 'Баланс покупателя',
    Store: 'Магазин',
    'Edit user account': 'Изменить аккаунт пользователя',
    'Full name': 'Полное имя',
    Close: 'Закрыть',
    Save: 'Сохранить',
    'Upload new photo': 'Загрузить новое фото',
    'Allowed JPG, GIF or PNG. Max size of 800K': 'Разрешены JPG, GIF или PNG. Макс. размер до 800K',
    Address: 'Адрес',
    'Vendor\’s balance': 'Баланс продавца',
    Company: 'Компания',
    Phone: 'Телефон',
    Country: 'Страна',
    'Post Code': 'Почтовый индекс',
    'Billing address': 'Платежный адрес',
    'Edit store': 'Редактировать магазин',
    Description: 'Описание',
    Created: 'Создан',
    'Store title': 'Имя магазина',
    'Upload store logo': 'Загрузить логотип магазина',
    'Upload new banner': 'Загрузить новый банер',
    'We carefully select sellers who can sell on the platform. Please describe your work experience and let us know how many products you plan to sell. You will be notified by email when we approve your application.': 'Мы тщательно отбираем продавцов, которые могут продавать на платформе. Пожалуйста, опишите свой опыт работы и сообщите нам, сколько продуктов вы планируете продавать. Вы будете уведомлены по электронной почте, когда мы одобрим вашу заявку.',
    'Upgrade account to Vendor': 'Обновить аккаунт до продавца',
    'update account': 'обновить аккаунт',
    'Update Account': 'Обновить Аккаунт',
    'The user will have access to the selected sections, pages.': 'Пользователю будут доступны выбранные разделы, страницы.',
    'Permissions': 'Разрешения'
  }